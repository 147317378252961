const enviromentConfig = {
    development: {
        endPoint: 'https://techmart.meu-solutions.com'
    },
    production: {
        //endPoint: 'https://haidanggroup.meu-solutions.com'
        // endPoint: 'https://gateway.dev.meu-solutions.com/caravan'
        endPoint: 'https://techmart.meu-solutions.com'
    },
    empty: {
        endPoint: 'https://techmart.meu-solutions.com'
    }
};
export default enviromentConfig;
